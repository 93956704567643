import * as React from 'react';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import { Switch, Route } from 'react-router';
import * as R from 'ramda';
import * as qs from 'qs';
import { cloneDeep } from 'lodash';

import { SubNavigationBar, TopNavigationBar } from '@common/components/navigation-bar';
import { EPredicateFilters } from '@common/components/predicates-filter';
import { Bar } from '@common/components/bar';
import ProfileImage from '@common/components/profile-image';
import Overview from '@common/components/overview';
import { Button } from '@common/components/button';
import Permission from '@common/components/permission';
import Confirm from '@common/components/confirm-button';
import Table from '@common/components/table';
import Container from '@common/components/container';
import BadgeCount from '@common/components/badgeCount';
import * as AlertService from '@common/services/alert';
import Access from '@common/components/access';

import UsersFilter from '@modules/admin/components/users/users-filter';
import { AccessLink } from '@modules/admin/components/access-link';
import { AccessRequestList } from '@modules/admin/components/access-request-list';
import UserActions from '@modules/admin/components/user-actions';
import InvitationForm from '@modules/network/forms/invitation';
import CelebrationsCard from '@modules/social/components/celebrations-widget/celebrations-card';

import { EUserStatus } from '@common/utils/user';
import { EPermissions, EPredicateFields, EPredicateOperators } from '@common/definitions';
import { pageWrapper, EEventNames } from '../../../../../client/analytics';

import * as networkSelector from '../../selectors/network';
import * as organisationSelector from '../../../organisation/selectors/organisation';
import * as userSelector from '../../../core/selectors/logged-user';

require('./styles.scss');

class EmployeesContainer extends React.Component {
  static defaultProps = {
    form: { values: {} },
  };

  constructor(props) {
    super();
    this.state = {
      networkFilters: {
        filter: EPredicateFilters[EPredicateFields.NETWORK],
        value: {
          attribute: EPredicateFields.NETWORK,
          comparison: EPredicateOperators.IN,
          value: props.network ? [props.network.id] : [],
        },
      },
      functionFilters: [],
    };

    this.handleViewUser = this.handleViewUser.bind(this);
    this.handleEditUser = this.handleEditUser.bind(this);
    this.handleResendInvitation = this.handleResendInvitation.bind(this);
    this.handleReinviteUsers = this.handleReinviteUsers.bind(this);
    this.setFilters = this.setFilters.bind(this);
  }

  componentDidMount() {
    const { roles, fetchRoles, fetchUserCounts } = this.props;

    fetchUserCounts();
    if (roles.network.length === 0) fetchRoles();
  }


  componentWillUnmount() {
    // Reset filters and clear invitation form
    this.props.destroyForm('network-invitation');
  }

  static props;


  handleViewUser(user) {
    const { loggedUser, history, match: { params } } = this.props;
    history.push(user.id === loggedUser.id ? '/profile/about' : `/networks/${params.networkId}/users/${user.id}/about`);
  }

  handleEditUser(user) {
    const { loggedUser, history, match: { params } } = this.props;
    history.push(user.id === loggedUser.id ? '/profile/about/edit' : `/networks/${params.networkId}/users/${user.id}/about/edit`);
  }

  async handleResendInvitation(user) {
    try {
      await this.props.resendInvitation(user);
      AlertService.success(
        <Trans
          i18nKey="network:user_reinvite_success"
          values={{ email: user.email }}
          components={[<b>a</b>]}
        />,
      );
    } catch (response) {
      AlertService.forStatus(response, {
        warning: this.props.t('network:user_reinvite_warning'),
        error: this.props.t('network:user_reinvite_error'),
      });
    }
  }

  async handleReinviteUsers() {
    const { history, match: { params }, reinviteUsers, t } = this.props;
    try {
      await reinviteUsers();
      history.push(`/networks/${params.networkId}/users`);
      AlertService.success(t('organisation:resend_invitations_success'));
    } catch (response) {
      AlertService.forStatus(response.status_code, {
        warning: t('organisation:resend_invitations_warning'),
        error: t('organisation:resend_invitations_error'),
      });
    }
  }

  setFilters(predicates) {
    const newNetworkFilters = cloneDeep(predicates.find((p) => p.filter === EPredicateFilters[EPredicateFields.NETWORK]));
    const newFunctionFilters = cloneDeep(predicates.find((p) => p.filter === EPredicateFilters[EPredicateFields.FUNCTION]));

    const networkFiltersChanged =
      JSON.stringify(this.state.networkFilters?.value?.value || []) !== JSON.stringify(newNetworkFilters?.value?.value || []);
    const functionFiltersChanged =
      JSON.stringify(this.state.functionFilters?.value?.value || []) !== JSON.stringify(newFunctionFilters?.value?.value || []);

    if (networkFiltersChanged) {
      this.setState({
        networkFilters: newNetworkFilters,
      });
    }
    if (functionFiltersChanged) {
      this.setState({
        functionFilters: newFunctionFilters,
      });
    }
  }

  render() {
    const {
      match: { params },
      location,
      loggedUser,
      organisation,
      network,
      networks,
      users,
      teams,
      functions,
      roles,
      counts,
      permissions,
      t,
    } = this.props;
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });

    const canSeeEmail = permissions.includes(EPermissions.NETWORK_USERS_VIEW);
    const filterEmptyColumn = R.reject(R.isNil);

    const columns = filterEmptyColumn([
      { className: 'Table__Cell--size-auto' },
      { label: this.props.t('network:user_name'), className: 'Table__Cell__Title fs-mask' },
      { label: this.props.t('network:user_phone_number'), className: 'fs-mask' },
      { label: this.props.t('organisation:users_columns_networks'), className: 'hidden-sd hidden-md fs-mask' },
      { label: this.props.t('organisation:users_columns_functions'), className: 'hidden-sd hidden-md fs-mask' },
    ]);

    const renderRow = ({ item: user }) => filterEmptyColumn([
      <ProfileImage size={35} user={user} />,
      (
        <div className="tw-flex tw-flex-col">
          <a onClick={() => this.handleViewUser(user)}>{user.full_name}</a>
          {canSeeEmail && (
            <div className="tw-text-gray-500 tw-mt-1">
              {user.email}
            </div>
          )}
        </div>
      ),
      user.phone_num ? <span>{user.phone_num}</span> : <small>{t('network:user_phone_empty')}</small>,
      user.networks && user.networks.length > 0 ? (
        <div>
          {user.networks[0]}{' '}
          {user.networks.length > 1 && (
            <BadgeCount
              title={user.networks.map((item) => (
                <div>{item}</div>
              ))}
              count={user.networks.length - 1}
            />
          )}
        </div>
      ) : (
        <small>-</small>
      ),
      user.functions && user.functions.length > 0 ? (
        <div>
          {user.functions[0]}{' '}
          {user.functions.length > 1 && (
            <BadgeCount
              title={user.functions.map((item) => (
                <div>{item}</div>
              ))}
              count={user.functions.length - 1}
            />
          )}
        </div>
      ) : (
        <small>-</small>
      ),
    ]);

    const statusLookup = {
      all: {
        filter: false,
        count: counts.total,
      },
      active: {
        filter: EUserStatus.ACTIVE,
        count: counts.active,
        tooltip: t('organisation:users_tooltip_active'),
      },
      inactive: {
        filter: EUserStatus.INACTIVE,
        count: counts.inactive,
        tooltip: t('organisation:users_tooltip_inactive'),
      },
      not_logged_in: {
        filter: EUserStatus.NOT_LOGGED_IN,
        count: counts.not_logged_in,
        tooltip: t('organisation:users_tooltip_not_logged_in'),
      },
      not_invited: {
        filter: EUserStatus.NOT_INVITED,
        count: counts.not_invited,
      },
      requests: {
        filter: 'requests',
        count: counts.access_requests,
      },
    };

    // Map url to status
    const currentStatus = statusLookup[params.filter] || statusLookup.all;
    const getUrlWithSearch = (url) => (query.q ? `${url}?q=${query.q}` : url);
    const defaultNetworks = this.props.network ? [this.props.network.id] : [];

    return (
      <>
        <Permission name={[EPermissions.ORGANISATION_USERS_VIEW, EPermissions.NETWORK_USERS_VIEW]}>
          <SubNavigationBar title={t('core:tab_coworkers')}>
            <SubNavigationBar.Item
              exact
              path={getUrlWithSearch(`/networks/${params.networkId}/users`)}
              title={t('organisation:users_filter_all')}
              count={counts.total}
            />
            <SubNavigationBar.Item
              path={getUrlWithSearch(`/networks/${params.networkId}/users/filter/active`)}
              title={t('organisation:users_filter_active')}
              count={counts.active}
            />
            <SubNavigationBar.Item
              path={getUrlWithSearch(`/networks/${params.networkId}/users/filter/inactive`)}
              title={t('organisation:users_filter_inactive')}
              count={counts.inactive}
              tooltip={t('organisation:users_tooltip_inactive')}
            />
            <SubNavigationBar.Item
              path={getUrlWithSearch(`/networks/${params.networkId}/users/filter/not_logged_in`)}
              title={t('organisation:users_filter_not_logged_in')}
              count={counts.not_logged_in}
            />
            <SubNavigationBar.Item
              path={getUrlWithSearch(`/networks/${params.networkId}/users/filter/not_invited`)}
              title={t('organisation:users_filter_not_invited')}
              count={counts.not_invited}
            />
            {organisation.access_request_links_enabled && (
              <Permission name={EPermissions.NETWORK_USERS_VIEW}>
                <SubNavigationBar.Item
                  path={getUrlWithSearch(`/networks/${params.networkId}/users/filter/requests`)}
                  title={t('organisation:users_filter_requests')}
                  count={counts.access_requests}
                />
              </Permission>
            )}
          </SubNavigationBar>
        </Permission>
        <Container name="NetworkUsers">
          <TopNavigationBar
            title={t('organisation:users_filter', { context: params.filter || 'all' })}
            tooltip={currentStatus.tooltip}
            count={currentStatus.count}
            action={(
              <>
                {organisation.access_request_links_enabled && (
                  <Permission name={EPermissions.NETWORK_USERS_CREATE}>
                    <AccessLink organisation={organisation} network={network} />
                  </Permission>
                )}
                {currentStatus.filter === EUserStatus.NOT_INVITED && currentStatus.count > 0 && (
                  <Confirm title={t('organisation:users_confirm_send_invitation')} onConfirm={this.handleReinviteUsers}>
                    <Button type="orange">
                      {t('organisation:users_invite_pending', { count: currentStatus.count })}
                    </Button>
                  </Confirm>
                )}
                <Permission name={EPermissions.NETWORK_USERS_CREATE}>
                  <InvitationForm teams={teams} functions={functions} roles={roles}>
                    <Button type="primary" iconRight="add" size="large">
                      <div style={{ display: 'inline' }}>
                        <span className="hidden-sd hidden-md">
                          {t('network:user_invite')}
                        </span>
                        <span className="visible-sd visible-md">
                          {t('network:user_invite_short')}
                        </span>
                      </div>
                    </Button>
                  </InvitationForm>
                </Permission>
              </>
            )}
          />
          <Container.Content>
            <Bar className="FiltersToolbarBar">
              <UsersFilter match={this.props.match} setFilters={this.setFilters} defaultNetworks={defaultNetworks} />
            </Bar>

            {!params.filter && (
              <CelebrationsCard
                networkIds={this.state.networkFilters?.value?.value || undefined}
                functionIds={this.state.functionFilters?.value?.value || undefined}
              />
            )}

            <Overview>
              <Switch>
                {organisation.access_request_links_enabled && (
                  <Route path="/networks/:networkId/users/filter/requests">
                    <AccessRequestList network={network} query={query.q} />
                  </Route>
                )}
                <Route>
                  <Table
                    columns={columns}
                    items={users}
                    data={{
                      onFetch: this.props.fetchUsers,
                      filter: {
                        status: currentStatus.filter || undefined,
                        query: query.q,
                        networkIds: this.state.networkFilters?.value?.value || undefined,
                        functionIds: this.state.functionFilters?.value?.value || undefined,
                      },
                    }}
                    renderRow={renderRow}
                    ActionComponent={({ item }) => {
                      // Warning! We are mapping the networks by name, would be better to recieve
                      // the full network object from the API.
                      // https://linear.app/oneteam/issue/ONE-2134/replace-v4organisationsidusers-with-v5organisationsidusers
                      const userNetworks = item.networks?.map((name) => {
                        const networkId = Object.keys(networks).find((key) => networks[key].name === name);
                        return networks[networkId];
                      }).filter((n) => !!n) || [];
                      return (
                        <Access
                          permissions={[
                            EPermissions.ORGANISATION_USERS_CREATE,
                            EPermissions.ORGANISATION_USERS_UPDATE,
                            EPermissions.ORGANISATION_USERS_REMOVE,
                          ]}
                          networkPermissions={[
                            EPermissions.NETWORK_USERS_UPDATE,
                            EPermissions.NETWORK_USERS_REMOVE,
                            EPermissions.NETWORK_USERS_CREATE,
                          ]}
                          networks={userNetworks}
                        >
                          <UserActions
                            item={item}
                            invitedAt={item.invited_at}
                            loggedUser={loggedUser}
                            networks={userNetworks}
                            onView={this.handleViewUser}
                            onEdit={this.handleEditUser}
                            onResendInvitation={this.handleResendInvitation}
                            onDelete={this.props.deleteUser}
                          />
                        </Access>
                      );
                    }}
                    placeholder={(
                      <div className="Users__Placeholder">
                        <div>
                          {t('network:user_filter_empty_placeholder')}
                          <br />
                          <img src="/static/images/people.svg" alt="PeopleVector" />
                        </div>
                      </div>
                    )}
                  />
                </Route>
              </Switch>
            </Overview>
          </Container.Content>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedUser: userSelector.selected(state),
  organisation: organisationSelector.selected(state),
  network: networkSelector.selected(state),
  networks: state.networks.items,
  permissions: userSelector.permissions(state),
  users: networkSelector.users(state),
  teams: networkSelector.teams(state),
  functions: networkSelector.functions(state),
  roles: organisationSelector.roles(state),
  form: state.form.invitation,
  ui: state.ui.network.employees,
  counts: state.network.users.counts,
});

const mapDispatchToProps = {
  fetchRoles: require('../../../organisation/actions').fetchRoles,
  fetchUsers: require('../../actions/fetch-users').default,
  fetchUserCounts: require('../../actions').fetchUserCounts,
  destroyForm: require('redux-form').destroy,
  resendInvitation: require('../../actions/resend-invitation').default,
  deleteUser: require('../../actions/delete-user').default,
  reinviteUsers: require('../../actions').reinviteUsers,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(pageWrapper(EEventNames.VISITED_EMPLOYEES_PAGE)(EmployeesContainer)),
);
