import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';

import { useCelebrations } from '@common/hooks/use-celebrations';
import Overview from '@common/components/overview';
import Spinner from '@common/components/spinner';
import { ProfileImagesGroup } from '@common/components/profile-image';
import { Button } from '@common/components/button';
import { network } from '@modules/core/selectors/logged-user';

import CelebrationsModal from './celebrations-modal';
import { Celebration } from './types';
import { useAppSelector } from '@common/hooks';

interface CelebrationsCardProps {
  networkIds?: string[];
  functionIds?: string[];
}

const CelebrationsCard = ({ networkIds, functionIds }: CelebrationsCardProps) => {
  const { celebrations, loading } = useCelebrations({ network_ids: networkIds, function_ids: functionIds });
  const selectedNetwork = useAppSelector(network);
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <Overview>
      <Overview.Content>
        <div className="tw-flex tw-items-center tw-gap-2">
          <img
            className="tw-mr-1 tw-h-5 tw-relative tw-top-0 tw-mt-[-3px] tw-mb-[-3px]"
            src="/static/images/social/star-sparkle.svg?url"
            alt="cake"
          />

          <div className="tw-flex tw-flex-col tw-flex-1">
            <span className="tw-text-lg tw-font-medium"><Trans i18nKey="social:celebrations_card_title" /></span>
            {loading ? (
              null
            ) : (
              <>
                {(celebrations.length > 0) ? (
                  <span>
                    <Trans
                      i18nKey="social:celebrations_summary"
                      values={{ count: celebrations.length > 1 ? `${celebrations.length} colleagues` : '1 colleague' }}
                    />
                  </span>
                ) : (
                  <span className="tw-text-gray-custom-700">
                    <Trans i18nKey="social:no_celebrations_today" />
                  </span>
                )}
              </>
            )}
          </div>

          <div className="tw-flex tw-flex-row tw-gap-2">
            {loading ? (
              <Spinner />
            ) : (
              <ProfileImagesGroup
                users={celebrations}
                size={32}
                limit={10}
                showTooltip
                tooltipKey="tooltip"
                renderTooltip={(user: Celebration) => {
                  if ('year' in user) {
                    return t('social:anniversary_today', { name: user.full_name, year: user.year });
                  }
                  return t('social:birthdays_today_other', { names: user.full_name });
                }}
                onClick={(user: Celebration) => {
                  history.push(`/networks/${selectedNetwork.id}/users/${user.id}/about`);
                }}
              />
            )}
            <Button type="white" size="small" onClick={toggleModal}>
              <Trans i18nKey="social:view_birthdays" />
            </Button>
          </div>
        </div>
      </Overview.Content>
      {showModal && (
        <CelebrationsModal open={showModal} onClose={toggleModal} networkIds={networkIds} functionIds={functionIds} />
      )}
    </Overview>
  );
};

export default CelebrationsCard;
